import nxModule from 'nxModule';
import _ from 'lodash';
import './customer-loan-create-lai-details.style.less';

import {cicContractTypes, interestSetting, repricingFrequency, restructureCreditType} from 'constants/loan';

const templateUrl = require('./customer-loan-create-lai-details.template.html');
nxModule.component('customerLoanCreateLaiDetails', {
  templateUrl: templateUrl,
  bindings: {
    formLai: '=',
    loanInformation: '=',
    loanCollaterals: '=',
    validateFiles: '=',
    categoryIds: '=',
    customFieldValues: '=',
    loanProduct: '<',
    customerId: '<',
    formMap: '=',
    setDefaultValues: '<',
    loanStatus: '<',
    groupLoan: '<',
    editMode: '<',
    branchId: '<'
  },
  controller: function ($scope, authentication, $timeout, dict, loanIndustryPurposeCache, misGroupsCache) {
    let that = this;
    that.dict = dict;

    that.$onInit = () => {
      if (that.setDefaultValues == null) that.setDefaultValues = true;
      that.setDefaults();
    };

    that.updateLoanSecurityOptions = () => {
      if(!that.loanProduct || !that.loanProduct.collateralRequirement || !that.dict.LOAN_SECURITY) {
        return;
      }
      that.visibleLoanSecurityOptions = that.dict.LOAN_SECURITY;

      switch (that.loanProduct.collateralRequirement) {
        case 'REQUIRED':
          that.visibleLoanSecurityOptions = that.dict['LOAN_SECURITY'].filter(loanSec => loanSec.id !== that.loanSecurityClean.id);
          break;
        case 'OPTIONAL':
          that.visibleLoanSecurityOptions = that.dict['LOAN_SECURITY'];
          break;
        case 'DISALLOWED':
        default:
          that.visibleLoanSecurityOptions = that.dict['LOAN_SECURITY'].filter(loanSec => loanSec.id === that.loanSecurityClean.id);
      }
    };

    that.cleanLoanSecuritySelected = () => {
      return that.loanSecurityClean?.id === that.loanInformation?.loanSecurityId;
    }

    dict.onLoadingComplete(() => {
      that.loanSecurityClean = _.find(that.dict['LOAN_SECURITY'], {code: 'CLN'});
      that.updateLoanSecurityOptions();

      if (that.setDefaults) {
        that.setDefaults();
      }
    });

    that.setDefaults = () => {
      if (!that.loanProduct || !that.setDefaultValues) return;

      if (that.loanInformation === undefined) {
        that.loanInformation = {};
      }
      if (that.loanProduct.defaultClassId) {
        let result = _.find(that.dict['LOAN_CLASS'], {id: that.loanProduct.defaultClassId});
        if (result) {
          that.loanInformation.loanClassId = result.id;
        }
      }
      if (that.loanProduct.defaultPurposeId) {
        let result = _.find(that.dict['LOAN_PURPOSE'], {id: that.loanProduct.defaultPurposeId});
        if (result) {
          that.loanInformation.loanPurposeId = result.id;
        }
      }
      if (that.loanProduct.defaultSecurityId) {
        let result = _.find(that.dict['LOAN_SECURITY'], {id: that.loanProduct.defaultSecurityId});
        if (result) {
          that.loanInformation.loanSecurityId = result.id;
        }
      }
      if (that.loanProduct.defaultEconomicActivityId) {
        let result = _.find(that.dict['LOAN_ECONOMIC_ACTIVITY'], {id: that.loanProduct.defaultEconomicActivityId});
        if (result) {
          that.loanInformation.loanEconomicActivityId = result.id;
        }
      }
      if (that.loanProduct.defaultLoanIndustryPurposeId) {
        that.loanInformation.loanIndustryPurposeId = that.loanProduct.defaultLoanIndustryPurposeId;
      }
      if (that.loanProduct.defaultMisGroupId) {
        that.loanInformation.misGroupId = that.loanProduct.defaultMisGroupId;
      }
      if (that.loanProduct.defaultMicrofinanceClassificationId) {
        that.loanInformation.loanMicrofinanceClassificationId = that.loanProduct.defaultMicrofinanceClassificationId;
      }

      if (that.loanProduct.defaultLoanBorrowerTypeId) {
        let result = that.dict['LOAN_BORROWER_TYPE'].find(entry => entry.id === that.loanProduct.defaultLoanBorrowerTypeId);
        if (result) {
          that.loanInformation.loanBorrowerTypeId = result.id;
        }
      }

      if (that.loanProduct.defaultLoanTransactionTypeId) {
        let result = that.dict['LOAN_TRANSACTION_TYPE'].find(entry => entry.id === that.loanProduct.defaultLoanTransactionTypeId);
        if (result) {
          that.loanInformation.loanTransactionTypeId = result.id;
        }
      }

      if (that.loanProduct.defaultCicContractType) {
        that.loanInformation.cicContractType = that.loanProduct.defaultCicContractType;
      }
    };

    $scope.$watch('$ctrl.formLai.$invalid', () => {
      if (that.customerId && that.formMap) {
        that.formMap[that.customerId] = that.formLai.$valid;
      }
    });

    that.$onChanges = (changes) => {
      if (changes.loanProduct.currentValue) {
        that.loanProduct = changes.loanProduct.currentValue;
        that.updateLoanSecurityOptions();
        this.setDefaults();
        this.setLoanSecurityType();
      }
    };

    that.cicContractTypes = [...cicContractTypes];
    that.interestSetting = [...interestSetting];
    that.repricingFrequency = [...repricingFrequency];
    that.restructureCreditType = [...restructureCreditType];

    // loan industry purpose
    that.loanIndustryPurposeSelected = null;

    that.loanIndustryPurposeTreeVisible = false;
    that.showLoanIndustryPurposeButton = ($event) => {
      that.loanIndustryPurposeTreeVisible = true;
      $event.stopPropagation();
    };

    that.loanIndustryPurposeSelected = (selected) => {
      if (selected.children.length === 0) {
        that.loanInformation.loanIndustryPurposeId = selected.id;
        that.loanIndustryPurposeTreeVisible = false;
      }
    };


    let loanPurposeSubscr = loanIndustryPurposeCache.toObservable().subscribe((groups) => {
        // build mis groups hierarchy
        let root = [];
        let idMap = {};
        buildHierarchy(groups, idMap, root);

        that.availableLoanIndustryPurposeGroups = root;
        that.loanIndustryPurposeIdMap = idMap;
      }
    );

    let misSubscr = misGroupsCache.toObservable().subscribe((groups) => {
        // build mis groups hierarchy
        let root = [];
        let idMap = {};
        buildHierarchy(groups, idMap, root);

        that.availableMisGroups = root;
        that.misGroupIdMap = idMap;
      }
    );

    function buildHierarchy(groups, idMap, root) {
      let codeMap = {};
      // first pass - populate code map
      for (let group of groups) {
        codeMap[group.code] = group;
        idMap[group.id] = group;
        group.children = [];
      }

      // second pass - build hierarchy based on map
      for (let group of groups) {
        if (group.parentCode) {
          const parent = codeMap[group.parentCode];
          parent.children.push(group);
        } else {
          root.push(group);
        }
      }
    }

    // mis groups
    that.misSelected = null;

    that.misTreeVisible = false;
    that.showMisButton = ($event) => {
      that.misTreeVisible = true;
      $event.stopPropagation();
    };

    that.misGroupSelected = (selected) => {

      if (selected.children.length === 0) {
        that.loanInformation.misGroupId = selected.id;
        that.misTreeVisible = false;
      }
    };

    that.misColDefs = [{field: "name"}];

    that.$onDestroy = () => {
      misSubscr.unsubscribe();
      loanPurposeSubscr.unsubscribe();
    };

    that.isEditModeEnabled = () => {
      return _.defaultTo(that.editMode, true);
    }

    that.updateRepricingFrequency = () => {
      if(that.loanInformation.interestSetting === 'FIXED') {
        that.loanInformation.repricingFrequency = null;
      }
    }

    that.setLoanSecurityType = () => {
      let loanSecurityType = '';
      const loanSecurity = that.visibleLoanSecurityOptions.find(d => d.id === that.loanInformation.loanSecurityId).description;
      if (['REM - Agricultural', 'REM - Commercial', 'REM - Residential'].includes(loanSecurity)) {
        loanSecurityType = 'REM';
      } else if ("Other Collateral" === loanSecurity) {
        loanSecurityType = 'OTR';
      } else if ("Chattel Mortgage" === loanSecurity) {
        loanSecurityType = 'CM';
      } else if ("Hold Out Deposit / Non Risk Asset" === loanSecurity) {
        loanSecurityType = 'HOD';
      }
      that.loanSecurityType = loanSecurityType;
    }

    that.getLoanSecurityType = () => {
      return that.loanSecurityType;
    }

  }
});
