import { ReactElement, useEffect } from "react"
import { Paper, Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router";
import useAxios from "axios-hooks";
import { CustomerProfile } from '../../../app/components/customer/profile/customer-profile.types';
import { HttpError } from "tools/HttpTypes";
import ProfilePicture from '../../../style/icons/icon-profile.svg'

const CustomerDetails = (): ReactElement => {
    const url = useLocation();
    const showCustomerDetails = url.pathname.includes('cocree');
    const { customerId } = useParams<{ customerId: string }>();
    const [{ data: customerData, loading }, fetchCustomerData] = useAxios<CustomerProfile, HttpError>(
      `/customers/${customerId}`, {
        manual: true
      }
    );

    useEffect(() => {
        if (showCustomerDetails) {
            fetchCustomerData()
        }
    }, [])

    return (
        <>
            {showCustomerDetails && !loading ? <>
                <Paper elevation={1}>
                    <Box p={3} mb={2} pl={1.5}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10rem",
                        }}>
                            <ProfilePicture />
                            <div>
                                <span style={{
                                    color: "gray"
                                }}>Name:</span>
                                <div>
                                    {customerData?.individualData ?  `${customerData?.effectiveName}`: customerData?.corporateData?.businessName}
                                </div>
                            </div>

                            <div>
                                <span style={{
                                    color: "gray"
                                }}>CIF:</span>
                                <div>
                                    {customerData?.customerNumber}
                                </div>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </> : null}
        </>
    )
}

export default CustomerDetails;