import {INgModelController} from 'angular';
import {flatten} from 'components/administration/areas/tree.utils';
import GeoLocationCache, {GeoLocation, GeoLocationRequest} from 'components/service/geo-location.cache';
import _ from 'lodash';
import nxModule from 'nxModule';
import {Dict} from 'shared/common/dict.types';
import {Address} from 'shared/customer-profile-commons/address.types';
import templateUrl from './address.template.html';

const GEO_LOCATION_MIN_LEVEL = 2;
const GEO_LOCATION_MAX_LEVEL = 4;

class AddressController {
  private ngModel!: INgModelController;
  private address!: Address;
  private editMode!: boolean;
  private philippinesCountryId!: number;
  private geoLocations: GeoLocation[] = [];
  private provinceGeoLocation?: GeoLocation | null;
  private cityGeoLocation?: GeoLocation | null;
  private barangayGeoLocation?: GeoLocation | null;

  constructor(private propertyConfigService: unknown, private dict: Dict, private geoLocationCache: GeoLocationCache) {
    this.propertyConfigService = propertyConfigService;
    this.dict = dict;
  }

  async $onInit(): Promise<void> {
    this.ngModel.$render = () : void => {
      this.address = this.ngModel.$modelValue;
    };
    const geoLocationReq: GeoLocationRequest = {
      minDepth: GEO_LOCATION_MIN_LEVEL,
      maxDepth: GEO_LOCATION_MAX_LEVEL,
      nested: true,
      sorting: 'ALPHABETICAL'
    }
    this.geoLocations = await this.geoLocationCache.withParam(geoLocationReq).toPromise();
    this.philippinesCountryId = this.dict.getId('COUNTRY', 'PH');
    this.initializeValues();
  }

  initializeValues(): void {
    const flattenedGeoLocations = flatten(this.geoLocations);
    const geoLocationByLevel = _.groupBy(flattenedGeoLocations, g => g.level);
    if (this.address.geoLocationId) {
      this.barangayGeoLocation = this.getGeoLocation(geoLocationByLevel, 4, this.address.geoLocationId);
      this.cityGeoLocation = this.getGeoLocation(geoLocationByLevel, 3, this.barangayGeoLocation == null ? this.address.geoLocationId : this.barangayGeoLocation.parentId);
      this.provinceGeoLocation = this.getGeoLocation(geoLocationByLevel, 2, this.cityGeoLocation == null ? this.address.geoLocationId : this.cityGeoLocation.parentId);
      this.setPsgcCode();
    }
  }

  getGeoLocation(geoLocationsByLevel: _.Dictionary<GeoLocation[]>, level: number, geoLocationId?: number): GeoLocation | undefined {
    return geoLocationsByLevel[level].find(gl => gl.id === geoLocationId);
  }

  /**
   * Set the most detailed geo location id to the model
   */
  setGeoLocationId() : void {
    this.address.geoLocationId = [this.barangayGeoLocation?.id, this.cityGeoLocation?.id, this.provinceGeoLocation?.id].find(id => id != null);
    this.setPsgcCode();
  }

  setPsgcCode() {
    this.address.psgcCode = [this.barangayGeoLocation?.psgcCode, this.cityGeoLocation?.psgcCode, this.provinceGeoLocation?.psgcCode].find(psgcCode => psgcCode != null);
  }

  resetForm() : void {
    this.address.province = null;
    this.address.barangay = null;
    this.address.city = null;
    this.address.geoLocationId = null;
    this.barangayGeoLocation = null;
    this.cityGeoLocation = null;
    this.provinceGeoLocation = null;
  }

  /**
   * This condition is only for migrated / existing cifs with no geo_location_id
   */
  displayPreviousValue(fieldValue: string): boolean {
    return this.editMode
      && this.address.countryId === this.philippinesCountryId
      && fieldValue != null
      && fieldValue.length > 0
      && [this.barangayGeoLocation?.id, this.cityGeoLocation?.id, this.provinceGeoLocation?.id].find(id => id != null) == null;
  }
}

nxModule.component('address', {
  controller: AddressController,
  templateUrl,
  require: {
    ngModel: '^ngModel'
  },
  bindings: {
    editMode: '=',
    module: '<',
    section: '<'
  }
})