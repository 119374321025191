import React, {ReactElement} from "react";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import RouteFallback from '../RouteFallback';
import ForceUpdateCredentials from "../management/user/ForceUpdateCredentials";
import WaitForCashTransfer from "../management/WaitForCashTransfer";
import Routes from "./Routes";
import {Box, Paper} from "@material-ui/core";
import NxBreadcrumb from "../breadcrumb/NxBreadcrumb";
import promptStore from "../form/nxProptStore";
import commandAccessService from "command/commandAccessService";
import {Redirect} from "react-router";
import {Component as CommandComponent} from "command/CommandService";
import PrintModal, {PrintModalApi} from "print/PrintModal";
import CustomerDetails from "customer/customer-details/CustomerDetails";

const AppRouter = (): React.ReactElement => {
  return <Router
    hashType='hashbang'
    getUserConfirmation={(message, callback): void => {
      if(!promptStore.isBlocking()) {
        // double confirmation - already approved by angularjs
        callback(true);
        return;
      }

      const confirmed = promptStore.presentChallenge();
      callback(confirmed);
    }}
  >
      <PrintModal>
          {(modalApi: PrintModalApi): ReactElement =>
              <CommandComponent printModalApi={modalApi}>
                  <ForceUpdateCredentials>
                      <WaitForCashTransfer>
                          <Switch>
                              {
                                  Routes.map(definition => {
                                      if (!definition.Component) {
                                          return null;
                                      }

                                      if (definition.commandAccess && !commandAccessService.canExecute(definition.commandAccess)) {
                                          // We still want to render route to ensure that it matches navigation, but
                                          // instead of a component we want to render error access page

                                          // In the future we could integrate command access for routes
                                          // with ButtonLink so that access can be specified in one place only
                                          return <Route path={definition.path} exact={true} key={definition.path}>
                                              <Redirect to={'/error-page/ACCESS'}/>
                                          </Route>;
                                      }

                                      return <Route path={definition.path} exact={true} key={definition.path}>
                                          <Box p={1}>
                                              <NxBreadcrumb/>
                                              <CustomerDetails/>
                                              <Box mb={1}>
                                                  <Paper elevation={1}>
                                                      <Box p={2}>
                                                          <definition.Component/>
                                                      </Box>
                                                  </Paper>
                                              </Box>
                                          </Box>
                                      </Route>;
                                  })
                              }
                              <RouteFallback/>
                          </Switch>
                      </WaitForCashTransfer>
                  </ForceUpdateCredentials>
              </CommandComponent>
          }
      </PrintModal>
  </Router>;
};

export default AppRouter;