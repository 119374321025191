import { ReactElement, useState } from "react"
import LinkList, { LinkListRoute } from 'LinkList';
import { useParams } from "react-router";
import { NxSelect, NxLoader, NxPopup, NxRow, NxButton, NxButtonVariant, NxRowPosition } from "@nextbank/ui-components";
import NxHeader from "form/NxHeader";
import axios from "axios";
import useAxios from "axios-hooks";
import { CustomerProfile } from '../../../app/components/customer/profile/customer-profile.types';
import { HttpError } from "tools/HttpTypes";

interface CocreeIndicator {
    id: number;
    cocreeIndicator: boolean;
}

const Cocree = (): ReactElement => {
    const { customerId } = useParams<{ customerId: string }>();
    const [{ data: customerData, loading: customerLoading }, fetchCustomerData] = useAxios<CustomerProfile, HttpError>(
        `/customers/${customerId}`
    );
    const [dropdownValue, setDropdownValue] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmDialog, showConfirmDialog] = useState<boolean>(false);

    const requestChange = async (): Promise<void> => {
        try {
            setLoading(true);
            await axios.post<CocreeIndicator[]>('/command/UpdateCustomerCocreeIndicator', {
                id: customerId,
                cocreeIndicator: dropdownValue
            });
            await fetchCustomerData();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    const links: LinkListRoute[] = [
        {
            name: 'Subject',
            path: `/customer/${customerId}/cocree/subject`
        },
        {
            name: 'Exposure',
            path: `/customer/${customerId}/cocree/exposure`
        },
        {
            name: 'Negative Information',
            path: `/customer/${customerId}/cocree/negative-information`
        },
    ];

    return (
        <>
            {loading || customerLoading ? <NxLoader/> : (
                <div>
                    {<>
                        <NxHeader>Cocree</NxHeader>
                        
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px"
                        }}>
                            <span style={{
                                marginTop: "10px"
                            }}>Report in Cocree?</span>
                            <div style={{
                                marginLeft: "20px",
                                maxWidth: "50rem",
                                minWidth: "auto",
                                width: "100%"
                            }}>
                                <NxSelect value={!!customerData?.cocreeIndicator}
                                    onChange={(changeValue): void => {
                                        setDropdownValue(changeValue!)
                                        showConfirmDialog(true)
                                    }}
                                    options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    label="" />
                            </div>
                        </div>
                    </>}
                    {customerData?.cocreeIndicator && !customerLoading ? <>
                        <LinkList links={links} header="" />
                    </> : null}

                    <NxPopup header="Confirm" open={confirmDialog} description={dropdownValue ? "Enable reporting of customer in Cocree?" : "Disable reporting of customer in Cocree?"}>
                        <NxRow position={NxRowPosition.END}>
                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => showConfirmDialog(false)}>
                                No
                            </NxButton>
                            <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                    requestChange()
                                    showConfirmDialog(false)
                                }}
                            >
                                Yes
                            </NxButton>
                        </NxRow>
                    </NxPopup>
                </div>
            )}
        </>

    )
}

export default Cocree;