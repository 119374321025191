import BigNumber from 'bignumber.js';
import {ownershipTypes} from 'constants/address';
import _ from 'lodash';
import nxModule from 'nxModule';

import templateUrl from './common-income-source.template.html';
import {incomeSourceTypes} from './constants';
import {employerTypes} from './constants';

class CommonIncomeSource {

  natureOfWorkOptions = [];
  natureTypeOptions = [];
  businessTypeOptions = [];
  businessStructureOptions = [];
  showRemarks = false;
  psicManualEntryIds = [];
  ownershipTypes = []

  constructor(dict, propertyConfigService)  {
    this.dict = dict;
    this.propertyConfigService = propertyConfigService;
    this.businessStructureOptions = ['SOLE_PROPRIETORSHIP', 'PARTNERSHIP', 'CORPORATION'];
    this.ownershipTypes = ownershipTypes;
  }

  $onInit() {
    this.cfg = this.propertyConfigService;
    this.types = incomeSourceTypes.filter(type => type.code !== 'DEPED');
    this.employerTypes = employerTypes;
    let defaultCountryCode = this.cfg.defaultValue('customer', 'income', 'country');
    const defaultCountry = _.find(this.dict['COUNTRY'], {code: defaultCountryCode});
    this.sourceOfIncome.companyAddress = this.sourceOfIncome.companyAddress || {}
    this.sourceOfIncome.companyAddress.countryId = this.sourceOfIncome.companyAddress.countryId || defaultCountry.id;
    this.dict.onLoadingComplete(() => {
      this.natureOfWorkOptions = this.dict['NATURE_OF_WORK_BUSINESS'];
      this.psicManualEntryIds = this.dict.filterByAttribute('NATURE_OF_WORK_BUSINESS', 'psicManualEntry', true).map(e => e.id);
      this.refreshNatureOfWorkFields();
    });
  }

  refreshNatureOfWorkFields = () => {
    this.natureTypeOptions = this.dict.filterByAttribute('NOWB_TYPE', 'parentDictEntryId', this.sourceOfIncome.natureOfWorkId);
    if (!this.natureTypeOptions.find(v => v.id === this.sourceOfIncome.natureTypeId)) {
      this.sourceOfIncome.natureTypeId = null;
    }

    this.businessTypeOptions = this.dict.filterByAttribute('NOWB_BUSINESS_TYPE', 'parentDictEntryId', this.sourceOfIncome.natureOfWorkId, this.sourceOfIncome.natureTypeId);
    if (!this.businessTypeOptions.find(v => v.id === this.sourceOfIncome.businessTypeId)) {
      this.sourceOfIncome.businessTypeId = null;
    }

    this.showRemarks = this.supportsRemarks(this.natureOfWorkOptions, this.sourceOfIncome.natureOfWorkId)
      || this.supportsRemarks(this.natureTypeOptions, this.sourceOfIncome.natureTypeId)
      || this.supportsRemarks(this.businessTypeOptions, this.sourceOfIncome.businessTypeId);

    this.updatePsicId();
  };

  set monthlyIncome(newValue) {
    this.sourceOfIncome.annualIncome = new BigNumber(newValue).multipliedBy(12).toNumber();
  }

  // use getter so that ng-model binding is possible and we won't need to update income source
  get monthlyIncome() {
    const annual = this.sourceOfIncome.annualIncome;

    if(annual === undefined || annual === null) {
      return annual;
    }

    return new BigNumber(annual).div(12).toNumber();
  }

  calculateMonthlyIncomeAmount = () => {
    if(this.sourceOfIncome.annualIncome){
      this.sourceOfIncome.monthlyIncome = new BigNumber(this.sourceOfIncome.annualIncome).dividedBy(12).toNumber();
    }
  }

  supportsRemarks(options, selectedValue) {
    const selectedOption = options && options.find(v => v.id === selectedValue);
    return selectedOption
      && selectedOption.attributes
      && selectedOption.attributes.hasOwnProperty('supportsRemarks')
      && Array.isArray(selectedOption.attributes.supportsRemarks)
      && selectedOption.attributes.supportsRemarks.includes(true);
  }

  updatePsicId = () => {
    this.sourceOfIncome.psicId = null;

    if (this.psicManualEntryIds.includes(this.sourceOfIncome.natureOfWorkId)){
      this.psicAutoSelect = false;
    } else {
      this.psicAutoSelect = true;
    }

    const psicDictEntryLinkIds =  (this.sourceOfIncome.natureOfWorkId || "") + ";" + (this.sourceOfIncome.natureTypeId || "") + ";" + (this.sourceOfIncome.businessTypeId || "");
    const calculatedPsic = this.dict.filterByAttribute('PSIC_INDUSTRY_CLASSIFICATION', 'psicDictEntryLinkIds', psicDictEntryLinkIds)[0];
    if(calculatedPsic) {
      this.sourceOfIncome.psicId = calculatedPsic.id;
    }
  }
}

nxModule.component('commonIncomeSource', {
  templateUrl,
  controller: CommonIncomeSource,
  bindings: {
    incomeFormTitle: '<',
    employmentFormTitle: '<',
    companyFormTitle: '<',
    performanceFormTitle: '<',
    editMode: '=',
    removeIncomeSource: '&',
    hasOnePrimaryIncomeSource: '&',
    primaryIncomeSourceChanged: '&',
    sourceOfIncome: '=',
    module: '<'
  }
});