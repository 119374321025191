import nxModule from 'nxModule';
import {IController, IIntervalService, ILocationService} from 'angular';
import _ from 'lodash';
import moment from 'moment';

import templateUrl from './batch-end-the-day.template.html';
import {statusMappings, WorkingDay, WorkingDayStatusMapping} from '../../../../../react/management/WorkingDayType';
import {Organization, OrganizationCache} from '../../../service/organization.cache';
import {Subscription} from 'rxjs';
import systemPropertyService from "../../../../../react/system/systemPropertyService";
import {Branch} from "management/BranchTypes";
import {WorkingDaysCache} from "components/service/working-days-cache";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import Popup from "shared/common/popup";
import {BranchService} from "components/service/branch.service";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {NxIFilterService} from "components/technical/angular-filters";
import config from 'config';
import Notification from "shared/utils/notification";

type HealthCheckStatus = 'Passed' | 'Failed' | 'Loading' | 'Ignored';

interface BranchListItem {
  selected: boolean;
  status: WorkingDayStatusMapping;
  healthCheckStatus: HealthCheckStatus;
}

interface HealthCheckSummary {
  branchId?: number;
  branchName: string;
  passed: boolean;
  failedHealthChecks: string[];
}

type BranchData = Branch & BranchListItem & Organization;

class BatchEndTheDayComponent implements IController {
  private readonly workingDayStatusMappings: WorkingDayStatusMapping[];
  private rootOrganization!: Organization;
  private branches!: BranchData[];
  private refetchPromise?: Promise<void>;
  private branchSubscription!: Subscription;
  private healthChecks!: HealthCheckSummary[];

  selectAll: boolean = false;
  branchIdJobStatusMap?: { [branchId: number]: string };

  private ignoreHealthCheck: boolean = false;
  private loading: boolean = true;

  constructor(private $location: ILocationService,
              private $interval: IIntervalService,
              private branchService: BranchService,
              private organizationCache: OrganizationCache,
              private workingDaysCache: WorkingDaysCache,
              private command: CommandService,
              private $route: NxRouteService,
              private $filter: NxIFilterService,
              private authentication: Authentication,
              private confirmationTemplate: ConfirmationTemplate,
              private http: HttpService,
              private notification: Notification,
              private popup: Popup) {
    this.workingDayStatusMappings = statusMappings;
  }

  async $onInit(): Promise<void> {
    await this.healthCheck();
    this.initBranchAndWorkingDayObservable();
    this.getBatchStatuses();
    this.loading = false;
  }

  private async healthCheck(): Promise<void> {
    this.ignoreHealthCheck = systemPropertyService.getProperty('ALLOW_EOD_DESPITE_HEALTH_CHECK_FAIL') === 'TRUE';
    this.healthChecks = !this.ignoreHealthCheck ? <HealthCheckSummary[]> await this.http.get(`/system/health`).toPromise() : [];
  }

  private initBranchAndWorkingDayObservable(): void {
    this.branchSubscription = this.branchService.toObservable()
      .combineLatest(this.organizationCache.toObservable(), (branches: Branch[], organizations: Organization[]) => {
          this.rootOrganization = <Organization>_.find(organizations, { 'root': true });
          return branches.map<Branch>(branch => {
            const organization = <Organization>_.find(organizations, { id: branch.organizationId });
            return { ...branch, organization };
          });
        }
      )
      .combineLatest(this.workingDaysCache.toObservable(), (branches: BranchData[], workingDays: WorkingDay[]) => {
        for (const branch of branches) {
          const workingDay = workingDays.find((workingDay: WorkingDay) => Number(workingDay.branchId) === Number(branch.id))!;
          const status: WorkingDayStatusMapping = this.workingDayStatusMappings.find(mapping => mapping.code === workingDay.status)!;
          branch.status = {
            code: workingDay.status,
            label: status.label
          };
          branch.systemDate = workingDay.systemDate;
          branch.selected = false;
          branch.healthCheckStatus = 'Loading';
        }
        return branches;
      }).subscribe(async (branches: BranchData[]): Promise<void> => {
        this.branches = branches
          .filter(branch => this.authentication.context.branchIds.includes(branch.id))
          .sort((a, b) => (a.code ?? '').localeCompare(b.code ?? ''));

        if(this.ignoreHealthCheck) {
          this.branches.forEach(b => b.healthCheckStatus = 'Ignored');
        } else {
          const bankHealthCheck = this.healthChecks.find((hc: HealthCheckSummary) => !hc.branchId);
          for(const branch of branches) {
            const branchHealthCheck = this.healthChecks.find((hc: HealthCheckSummary) => hc.branchId === branch.id);
            branch.healthCheckStatus = branchHealthCheck?.passed && (!bankHealthCheck || bankHealthCheck.passed) ? 'Passed' : 'Failed';
          }
        }
    });
  }

  onIndividualEODClicked(branch: BranchData): void {
    if (!this.canEOD(branch)) return;
    this.$location.path(`/dashboard/miscellaneous-transactions/batch-end-the-day/${ branch.id }`);
  }

  canEOD(branch: BranchData): boolean {
    return branch?.status?.code === 'COUNTER_CLOSED' && ['Passed', 'Ignored'].includes(branch.healthCheckStatus);
  }

  onBranchCheckboxClicked(currentValue: boolean): void {
    if (this.selectAll && !currentValue) {
      this.selectAll = false;
    }
  }

  onSelectAllChange(currentValue: boolean): void {
    this.branches.forEach(branch => {
      if (this.canEOD(branch)) {
        branch.selected = currentValue;
      }
    })
  }

  isSelectAllEnabled(): boolean {
    return (this.branches || []).some(branch => this.canEOD(branch));
  }

  isStartBatchEnabled(): boolean {
    return (this.branches || []).some(branch => branch.selected);
  }

  async onStartBatchClicked(): Promise<void> {
    const confirmationDetails = this.branches
      .filter(branch => branch.selected)
      .map(branch =>
        ({
          label: branch.name,
          description: this.$filter('prettyDate')(moment(branch.systemDate).toDate())
        }));

    const proceed = await this.confirmationTemplate({
      question: 'Do you want to process batch EOD for the following branches?',
      details: confirmationDetails,
      warning: 'This operation cannot be reverted safely.<br>Please make sure that the day can be closed and you are closing the correct day.'
    });
    if (!proceed) return;

    const selectedBranchIds = this.getSelectedBranches();
    const { output } = await this.command.execute<unknown, { branchIdErrorMap: Record<number, string> }>('BatchEndDay', { branchIds: selectedBranchIds }).toPromise();

    if (!_.isEmpty(output.branchIdErrorMap)) {
      let errorDetails: string = `Following branches encountered an error while starting EOD:<br>`;
      for (const [branchId, msg] of Object.entries(output.branchIdErrorMap)) {
        const branch: BranchData | undefined = this.branches.find(branch => Number(branch.id) === Number(branchId));
        errorDetails = `${ errorDetails } <strong>${ branch?.name }</strong>: ${ msg }<br>`;
      }
      this.popup({ header: 'Info', text: errorDetails, renderHtml: true });
    } else {
      this.notification.show('Success', 'Successfully started multiple EODs.');
    }

    this.fetchStepStatuses(false);
    this.setupStatusRefresh();
  }

  private setupStatusRefresh(): void {
    // Refetch steps statuses every [batchProcessStatusFetchInterval] ms
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.refetchPromise = this.$interval(() => this.fetchStepStatuses(), config.batchProcessStatusFetchInterval);
  }

  private fetchStepStatuses(skipLoader = true): void {
    this.branchService.refetch();
    this.workingDaysCache.refetch();
    this.getBatchStatuses(skipLoader);
  }

  private getBatchStatuses(skipLoader = true): void {
    // Read batch process step statuses from server
    this.http.get('/batch-jobs/branch-status?jobTypes=BANK_EOD', { nxLoaderSkip: skipLoader })
      .success((stepStatuses) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.branchIdJobStatusMap = stepStatuses;
      })
      .error(() => this.notification.show('Error', 'Failed to fetch batch process status'));
  }

  private getSelectedBranches(): number[] {
    return this.branches
      .filter(branch => branch.selected)
      .map(branch => branch.id);
  }

  onExitClicked(): void {
    this.$location.path('/dashboard/miscellaneous-transactions');
  }

  $onDestroy(): void {
    if (this.refetchPromise) {
      this.$interval.cancel(this.refetchPromise);
    }
    this.branchSubscription.unsubscribe();
  }
}

nxModule.component('batchEndTheDay', {
  templateUrl,
  controller: BatchEndTheDayComponent
});
