import {NxQuery, NxQueryResult, NxStack, NxTable, NxTableColumn} from "@nextbank/ui-components";
import React, {ReactElement} from "react";
import NxHeader from "../../form/NxHeader";
import NxPage from "../../form/NxPage";
import {useParams} from "react-router";
import {Checkbook, CheckbookEntry} from "check/CheckbookType";
import axios from "axios";
import {PageResult} from "tools/HttpTypes";
import useAxios from "axios-hooks";
import EnumFormatter from "tools/EnumFormatter";
import { NxTableDataTest } from "@nextbank/ui-components/dist/nxTable/NxTableTypes";

interface CheckbookEntryDetals extends CheckbookEntry {
  statusText: string;
}


const columns: NxTableColumn<CheckbookEntryDetals>[] = [{
  title: "No",
  field: "lineNo",
}, {
  title: "Check number",
  field: "checkNumber",
}, {
  title: "Status",
  field: "statusText",
}];

const dataTest : NxTableDataTest = {
  tableName : 'tableChecks',
  rowName : 'rowItemChecks',
  cellNames : [
    'colNo',
    'colCheckNumber',
    'colStatus'
  ]
}


const enumFormatter: EnumFormatter = new EnumFormatter();

const CheckList = (): ReactElement =>  {
  const params = useParams<{ depositoryAccountId: string, checkbookId: string }>();
  const [{data: checkbook}] = useAxios<Checkbook>(`/checks/checkbooks/${params.checkbookId}`);

  return <NxPage>
    <NxHeader>
      Checks for checkbook {checkbook?.number ?? ''}
    </NxHeader>
    <NxStack>
      <NxTable
        columns={columns}
        data={async (query: NxQuery): Promise<NxQueryResult<CheckbookEntryDetals>> => {
          const {data} = await axios.post<PageResult<CheckbookEntry>>('/checks/checkbooks/entries', {
            checkbookIds: [params.checkbookId],
            checkbookOwner: 'BANK',
            pageNo: query.page,
            pageSize: query.pageSize
          });

          return {
            ...data,
            result: data.result.map(res => ({
              ...res,
              lineNo: res.lineNo + 1,
              statusText: enumFormatter.format(res.status.toString())
            })),
          };
        }}
        dataTest={dataTest}
      />
    </NxStack>
  </NxPage>;
};

export default CheckList