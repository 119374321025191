import {NxSelectOption} from '@nextbank/ui-components';
import {CustomerType} from 'components/customer/profile/customer-profile.types';
import {AtLeastOne} from 'shared/utils/AtleastOne';

export interface CustomFieldCategory {
  id?: number;
  parentId?: number;
  definitionId?: number;
  value: string;
  code?: string;
  remarks: string;
  enabled: boolean;
  customFieldDefinitionId?: number;
  children: CustomFieldCategory[];
}

export interface CustomFieldCategoryDetails {
  id?: number;
  definitionId: number;
  categoryTypeName: string;
  categoryId: number;
  categoryName: string;
  categoryParentId: number;
  children: CustomFieldCategoryDetails[];
  customFieldDefinitionName?: string;
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CATEGORY = 'CATEGORY'
}

export interface CustomFieldDefinition {
  id: number;
  name: string;
  type: CustomFieldType,
  customerTypes?: CustomerType[] | null
  enabled: boolean;
  showInReports: boolean;
  group: string;
  availableInBranchIds: number[];
  required: boolean;
}

export interface CustomFieldDefinitionCriteria {
  ids?: number[]
  customerType?: CustomerType
  groups?: string[];
  enabled?: boolean;
}

export enum CustomFieldGroup {
  LOAN = 'LOAN',
  ACCOUNT = 'ACCOUNT',
  DEPOSIT = 'DEPOSIT',
  CUSTOMER = 'CUSTOMER',
  COCREE = 'COCREE'
}

export type CustomFieldValueCriteria = AtLeastOne<{
  productId: number;
  customerId: number;
}>

export type RestrictedFieldType = 'MIS' | 'CUSTOM';
export type CATEGORY_GROUP = 'ACCOUNT' | 'DEPOSIT' | 'LOAN' | 'CUSTOMER' | 'COCREE';
export const categoryGroupSelect: NxSelectOption<CATEGORY_GROUP>[] = [
         {
           label: "Customer",
           value: "CUSTOMER",
         },
         {
           label: "Account",
           value: "ACCOUNT",
         },
         {
           label: "Deposit",
           value: "DEPOSIT",
         },
         {
           label: "Loan",
           value: "LOAN",
         },
         {
           label: "Cocree",
           value: "COCREE",
         },
       ];

export interface RestrictedFieldDefault {
  categoryId?: number | null;
  value?: string | null;
}
export interface CustomFieldRestriction {
  id?: number | null;
  customFieldId: number;
  customFieldCategoryId: number;
  restrictedFieldType: RestrictedFieldType;
  restrictedFieldId: number;
  restrictedFieldDefault? : RestrictedFieldDefault | null;
}
export interface CustomFieldRestrictionSearchCriteria {
  id?: number;
  customFieldIds?: number[]
  customFieldCategoryId?: number;
  restrictedFieldIds?: number[];
  restrictedFieldType?: RestrictedFieldType;
}

export interface CustomFieldRestrictionDetails {
  id: number;
  category: string;
  restrictedFieldType: RestrictedFieldType;
  restrictedField: string;
}
