import {ContractStatus} from 'components/administration/loan/common/loan-enum-types';
import {NegativeInformation} from 'components/service/loan.types';
import {ProductStatus} from 'components/service/product.types';
import {contractStatus, restructureCreditType} from 'constants/loan';
import {DictEntry} from 'dictionary/DictionaryType';
import nxModule from 'nxModule';
import templateUrl from 'components/customer/loans/new-loan/page/details/negative-information/negative-information.template.html';
import {Dict} from 'shared/common/dict.types';

class NegativeInformationController {

  protected negativeInformation!: NegativeInformation;
  protected editMode!: boolean;
  protected loanStatus!: ProductStatus;

  constructor(private dict: Dict) {
  }

  async $onInit(): Promise<void> {
    await this.dict.onLoadingCompleteAsync();
    const negativeInformation = <DictEntry[]> this.dict['LOAN_NEGATIVE_INFORMATION'];
    const foreclosureEntry = negativeInformation.find(de => de.code === 'FORECLOSURE');
  }

  isEditModeEnabled(): boolean {
    return this.editMode;
  }

  contractStatus = [...contractStatus];
}

nxModule.component('negativeInformation', {
  templateUrl,
  bindings: {
    formNi: '=',
    editMode: '<',
    negativeInformation: '=',
    loanStatus: '<'
  },
  require: {
    form: '?^form',
  },
  controller: NegativeInformationController
});


