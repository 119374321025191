export type GuaranteeCustomerType = 'DOMESTIC' | 'FOREIGN';

export type GuaranteeType = 'SURETY_PAYMENT_GUARANTEE' |
  'REM_INSURED_PHILGUARANTEE' |
  'REM_NOT_INSURED_PHILGUARANTEE' |
  'CHATTEL_MORTGAGE' |
  'HOLDOUT_PESO_DEPOSITS' |
  'HOLDOUT_FCDU_DEPOSITS' |
  'ASSIGNMENT_PLEDGE_GOVT_SECURITIES' |
  'CASH_MARGIN_DEPOSIT' |
  'ASSIGNMENT_EXPORT_LC_PO_CONTRACT' |
  'GUARANTEED_NATIONAL_GOVERNMENT' |
  'GUARANTEED_LOCAL_GOVERNMENT_UNIT' |
  'GUARANTEED_PHILIPPINE_INC_BANK' |
  'GUARANTEED_MULTILATERAL_DEVELOPMENT_BANKS' |
  'GUARANTEED_FOREIGN_INC_BANK' |
  'GUARANTEED_FOREIGN_NON_CENTRAL_PUBLIC_SECTOR' |
  'SHARES_STOCKS_PHILIPPINE_INC_BANKS_PSE_LISTED' |
  'SHARES_STOCKS_PHILIPPINE_INC_BANKS_UNLISTED' |
  'SHARES_STOCKS_OTHER_PHILIPPINE_INC_CORP_PSE_LISTED' |
  'SHARES_STOCKS_OTHER_PHILIPPINE_INC_CORP_UNLISTED' |
  'DEED_ASSIGNMENT_ACCOUNTS_RECEIVABLE' |
  'MORTGAGE_TRUST_INDENTURE' |
  'GUARANTEED_IGLF' |
  'GUARANTEED_PHILGUARANTEE' |
  'GUARANTEED_GFSME_SBGFC' |
  'GUARANTEED_GOVERNMENT_AGENCIES' |
  'SECURITIES_ISSUED_NATIONAL_GOVERNMENT' |
  'SECURITIES_ISSUED_GOVERNMENT_AGENCIES' |
  'SECURITIES_ISSUED_LOCAL_GOVERNMENT_UNIT' |
  'SECURITIES_ISSUED_SPVS_ASSIGNMENT_AR' |
  'SECURITIES_ISSUED_PHILIPPINE_INC_BANK' |
  'SECURITIES_ISSUED_FOREIGN_INC_BANK' |
  'SECURITIES_ISSUED_PHILIPPINE_INC_CORPORATIONS' |
  'SECURITIES_ISSUED_FOREIGN_INC_CORPORATIONS' |
  'SECURITIES_ISSUED_MULTILATERAL_DEVELOPMENT_BANKS' |
  'SECURITIES_ISSUED_FOREIGN_NON_CENTRAL_PUBLIC_SECTOR' |
  'OTHER_SECURED_GUARANTEE' |
  'INSURANCE_CONTRACT';

export const guaranteeTypes = Object.freeze([
  {label: 'Surety / Payment Guarantee', value: 'SURETY_PAYMENT_GUARANTEE'},
  {label: 'REM – Insured by PHILGUARANTEE', value: 'REM_INSURED_PHILGUARANTEE'},
  {label: 'REM – Not Insured by PHILGUARANTEE', value: 'REM_NOT_INSURED_PHILGUARANTEE'},
  {label: 'Chattel Mortgage', value: 'CHATTEL_MORTGAGE'},
  {label: 'Holdout vs. Peso Deposits/Deposit substitute', value: 'HOLDOUT_PESO_DEPOSITS'},
  {label: 'Holdout vs. FCDU Deposits/Deposit substitute', value: 'HOLDOUT_FCDU_DEPOSITS'},
  {label: 'Assignment/Pledge of Govt Securities', value: 'ASSIGNMENT_PLEDGE_GOVT_SECURITIES'},
  {label: 'Cash Margin Deposit', value: 'CASH_MARGIN_DEPOSIT'},
  {label: 'Assignment of Export LC/PO/Sales and/or Service Contract', value: 'ASSIGNMENT_EXPORT_LC_PO_CONTRACT'},
  {label: 'Guaranteed by National Government (RP)', value: 'GUARANTEED_NATIONAL_GOVERNMENT'},
  {label: 'Guaranteed by Local Government Unit (LGU)', value: 'GUARANTEED_LOCAL_GOVERNMENT_UNIT'},
  {label: 'Guaranteed by Philippine Incorporated Bank/Quasi-Bank', value: 'GUARANTEED_PHILIPPINE_INC_BANK'},
  {label: 'Guaranteed by Multilateral Development Banks', value: 'GUARANTEED_MULTILATERAL_DEVELOPMENT_BANKS'},
  {label: 'Guaranteed by Foreign Incorporated Bank', value: 'GUARANTEED_FOREIGN_INC_BANK'},
  {label: 'Guaranteed by non-central government public sector entities of foreign countries', value: 'GUARANTEED_FOREIGN_NON_CENTRAL_PUBLIC_SECTOR'},
  {label: 'Shares of Stocks  of Philippine Incorporated Banks/Quasi-banks- PSE Listed', value: 'SHARES_STOCKS_PHILIPPINE_INC_BANKS_PSE_LISTED'},
  {label: 'Shares of Stocks  of Philippine Incorporated Banks/Quasi-banks- Unlisted', value: 'SHARES_STOCKS_PHILIPPINE_INC_BANKS_UNLISTED'},
  {label: 'Shares of Stocks of other Philippine Incorporated Corporations - PSE Listed', value: 'SHARES_STOCKS_OTHER_PHILIPPINE_INC_CORP_PSE_LISTED'},
  {label: 'Shares of Stocks of other Philippine Incorporated Corporations - Unlisted', value: 'SHARES_STOCKS_OTHER_PHILIPPINE_INC_CORP_UNLISTED'},
  {label: 'Deed of Assignment of Accounts Receivable', value: 'DEED_ASSIGNMENT_ACCOUNTS_RECEIVABLE'},
  {label: 'Mortgage Trust Indenture', value: 'MORTGAGE_TRUST_INDENTURE'},
  {label: 'Guaranteed by IGLF', value: 'GUARANTEED_IGLF'},
  {label: 'Guaranteed by PHILGUARANTEE', value: 'GUARANTEED_PHILGUARANTEE'},
  {label: 'Guaranteed by GFSME/SBGFC', value: 'GUARANTEED_GFSME_SBGFC'},
  {label: 'Guaranteed by Government Agencies', value: 'GUARANTEED_GOVERNMENT_AGENCIES'},
  {label: 'Securities issued by National Government (RP)', value: 'SECURITIES_ISSUED_NATIONAL_GOVERNMENT'},
  {label: 'Securities issued by Government Agencies', value: 'SECURITIES_ISSUED_GOVERNMENT_AGENCIES'},
  {label: 'Securities issued  by Local Government Unit (LGU)', value: 'SECURITIES_ISSUED_LOCAL_GOVERNMENT_UNIT'},
  {label: 'Securities issued by SPVs against assignment of A/R (RA 6957)', value: 'SECURITIES_ISSUED_SPVS_ASSIGNMENT_AR'},
  {label: 'Securities issued by Philippine Incorporated Bank/Quasi Bank', value: 'SECURITIES_ISSUED_PHILIPPINE_INC_BANK'},
  {label: 'Securities issued by Foreign Incorporated Bank', value: 'SECURITIES_ISSUED_FOREIGN_INC_BANK'},
  {label: 'Securities issued by Philippine Incorporated Corporations', value: 'SECURITIES_ISSUED_PHILIPPINE_INC_CORPORATIONS'},
  {label: 'Securities issued by Foreign Incorporated Corporations', value: 'SECURITIES_ISSUED_FOREIGN_INC_CORPORATIONS'},
  {label: 'Securities issued by Multilateral Development Banks', value: 'SECURITIES_ISSUED_MULTILATERAL_DEVELOPMENT_BANKS'},
  {label: 'Securities issued by non-central government public sector entities of foreign countries', value: 'SECURITIES_ISSUED_FOREIGN_NON_CENTRAL_PUBLIC_SECTOR'},
  {label: 'Other secured guarantee', value: 'OTHER_SECURED_GUARANTEE'},
  {label: 'Insurance Contract', value: 'INSURANCE_CONTRACT'}
]);

