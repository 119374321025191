interface BaseLocation {
  id: number;
  name?: string;
  parentId?: number | null;
  psgcCode?: string;
  level?: number;
  city?: boolean | null;
  orderNo?: number | null;
}

interface Province extends BaseLocation {
  children?: BaseLocation[];
}

type LocationData = Province[];

interface SimplifiedProvince {
  value: number;
  label: string;
}

interface SimplifiedCity {
  value: number;
  label: string;
  parentId?: number | null;
}

interface LocationFilter {
  provinces: Omit<Province, "children">[];
  getProvinces: () => SimplifiedProvince[];
  getCitiesForProvince: (provinceId: number) => SimplifiedCity[];
  getCity: (provinceId: number, cityId: number) => BaseLocation | null;
  getProvince: (provinceId: number) => Omit<Province, "children"> | null;
}

export const filterLocations = (locations?: LocationData): LocationFilter => {
  const safeLocations = locations || [];

  const provinces: Omit<Province, "children">[] = safeLocations.map(({ children, ...location }) => location);

  const getProvinces = (): SimplifiedProvince[] => {
    return safeLocations.map(({ id, name }) => ({
      label: name ?? "Unknown",
      value: id,
    }));
  };

  const getCitiesForProvince = (provinceId: number): SimplifiedCity[] => {
    const province = safeLocations.find((loc) => loc.id === provinceId);
    return province && province.children
      ? province.children.map((city) => ({
          value: city.id,
          parentId: city.parentId,
          label: city.name ?? "Unknown",
        }))
      : [];
  };

  const getCity = (provinceId: number, cityId: number): BaseLocation | null => {
    const province = safeLocations.find((loc) => loc.id === provinceId);
    return province && province.children ? province.children.find((city) => city.id === cityId) || null : null;
  };

    const getProvince = (provinceId: number): Omit<Province, "children"> | null => {
      const province = safeLocations.find((loc) => loc.id === provinceId);
      if (province) {
        const { ...provinceWithoutChildren } = province;
        return provinceWithoutChildren;
      }
      return null;
    };

  return {
    provinces,
    getProvinces,
    getCitiesForProvince,
    getCity,
    getProvince
  };
};
