import GlMappingsService from 'components/administration/gl-mappings/gl-mappings.service';
import {AccountWithLabel, addAccountLabels} from 'components/general-ledger/common/gl.utils';
import nxModule from 'nxModule';
import moment from 'moment';
import _ from 'lodash';
import {Dict} from 'shared/common/dict.types';

import templateUrl from './global-settings.template.html';
import './global-settings.style.less';
import systemPropertyService from '../../../../react/system/systemPropertyService';
import {
  StartDayAndCloseCounterMode
} from '../../technical/automated-eod-notification/automated-eod-notification.component';
import {OrganizationCache} from 'components/service/organization.cache';
import {
  LedgerTag
} from 'components/administration/general-ledger/misc-transaction-mapping/misc-transaction-mapping.service';
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from "shared/utils/command/command.types";
import {LedgerTagCache} from "components/service/ledger/ledger-tag.cache.types";
import {HttpService} from "shared/utils/httpService";
import {EmailNotificationConfig} from 'components/administration/email-notification/email-notification-config.component';

interface AutomatedEodConfig {
  enabled: boolean;
  performAtTime: Date;
  notificationTime: Date;
  startDayMode: StartDayAndCloseCounterMode;
  closeCounterMode: StartDayAndCloseCounterMode;
}

interface OrganizationConfig {
  name: string;
  cicProviderCode: string;
  bspCode: string;
  bsfiCode: string;
  depEdDeductionCode: string;
  bankId: number;
}

interface OfficialReceiptConfig {
  automationEnabled: boolean;
}

export type CollectionReceiptType = 'OFFICIAL' | 'TEMPORARY';

interface CollectionsConfig {
  receiptType: CollectionReceiptType;
}

interface DirectTransferConfig {
  tags: string;
}

export type SmsNotificationChannel = 'CBS' | 'MOBILE';

interface SmsNotificationChannelOption {
  label: string;
  value: SmsNotificationChannel
}

interface SmsNotificationConfig {
  channels: SmsNotificationChannel[];
  template: string;
}

interface PchcConfig {
  directClearingMember: boolean
  ledgerAccountCode?: string
}

interface AMLAInstitutionCodeConfig {
  required: boolean;
}

interface GlobalSettings {
  organizationConfig: OrganizationConfig;
  bookToHeadOffice: boolean;
  automatedEodConfig: AutomatedEodConfig;
  officialReceiptConfig: OfficialReceiptConfig;
  collectionsConfig: CollectionsConfig;
  beforeEodEmailConfig: EmailNotificationConfig;
  startEodEmailConfig: EmailNotificationConfig;
  endEodEmailConfig: EmailNotificationConfig;
  successEodEmailConfig: EmailNotificationConfig;
  failedBranchEodEmailConfig: EmailNotificationConfig;
  longRunningEodEmailConfig: EmailNotificationConfig;
  oracleHandoffEmailConfig: EmailNotificationConfig;
  successfulOracleHandoffEmailConfig: EmailNotificationConfig;
  healthCheckEmailConfig: EmailNotificationConfig;
  directTransferConfig: DirectTransferConfig;
  smsNotificationConfig: SmsNotificationConfig;
  pchcConfig: PchcConfig;
  bspCode: string;
  amlaInstitutionCodeConfig: AMLAInstitutionCodeConfig;
  displayRealServerDateTime: boolean;
  healthCheckJobEnabled: boolean;
}

class GlobalSettingsComponent {
  protected readonly startDayAndCloseCounterModeOptions = StartDayAndCloseCounterMode;

  protected readonly collectionReceiptTypes: CollectionReceiptType[] = ['OFFICIAL', 'TEMPORARY'];
  protected readonly smsNotificationChannelOptions: SmsNotificationChannelOption[] = [
    {
      label: 'CBS',
      value: 'CBS'
    },
    {
      label: 'Mobile',
      value: 'MOBILE'
    }
  ]

  private model: Partial<GlobalSettings> = {};
  private smsSupportEnabled!: boolean;

  private ledgerAccounts: AccountWithLabel[] = [];
  private ledgerTags: LedgerTag[] = [];

  private readonly ledgerAccountSelectConfig = {
    placeholder: 'Select ledger account',
    searchField: 'label',
    valueField: 'fullCode',
    labelField: 'label',
    maxItems: 1
  }

  constructor(
    private readonly organizationCache: OrganizationCache,
    private readonly ledgerTagCache: LedgerTagCache,
    private readonly command: CommandService,
    private readonly confirmation: Confirmation,
    private readonly http: HttpService,
    private readonly glMappingsService: GlMappingsService,
    private readonly dict: Dict
  ) {
  }

  async $onInit(): Promise<void> {
    const [organizations, emailConfigurations, accounts, ledgerTags] = await Promise.all([
      this.organizationCache.toPromise(),
      this.http.get<EmailNotificationConfig[]>('/management/email-notification-configs').toPromise(),
      this.glMappingsService.accounts.toPromise(),
      this.ledgerTagCache.toPromise(),
      this.dict.onLoadingCompleteAsync()
    ]);

    this.ledgerTags = ledgerTags;

    /*
     * Organization
     */
    const rootOrganization = organizations.find(o => o.root);
    if (!rootOrganization) {
      throw new Error('Root organization not found');
    }

    this.model.organizationConfig = {
      name: rootOrganization.name,
      cicProviderCode: rootOrganization.cicProviderCode,
      bspCode: rootOrganization.bspCode,
      bsfiCode: rootOrganization.bsfiCode,
      depEdDeductionCode: rootOrganization.depEdDeductionCode,
      bankId: rootOrganization.bankId
    };

    this.model.beforeEodEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'BEFORE_EOD');
    this.model.startEodEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'START_OF_EOD');
    this.model.successEodEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'SUCCESSFUL_EOD');
    this.model.failedBranchEodEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'FAILED_BRANCH_EOD');
    this.model.longRunningEodEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'LONG_RUNNING_EOD');
    this.model.successfulOracleHandoffEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'SUCCESSFUL_ORACLE_HANDOFF_GENERATION');
    this.model.healthCheckEmailConfig = emailConfigurations.find((e: EmailNotificationConfig) => e.messageType === 'HEALTH_CHECK');
    /*
     * Accounting Period
     */
    this.model.bookToHeadOffice = systemPropertyService.getProperty('AP_BOOK_TO_HEADOFFICE') === 'TRUE';
    /*
     * Check if Health Check Job is enabled
     */
    this.model.healthCheckJobEnabled = systemPropertyService.getProperty('HEALTH_CHECK_JOB_ENABLED') === 'TRUE';

    /*
     * Automated EOD
     */
    const [enabled, performAtTime, notificationBeforeInMinutes, startDayMode, closeCounterMode] = [
      systemPropertyService.getProperty('AUTOMATED_EOD_ENABLED') === 'TRUE',
      moment(systemPropertyService.getPropertyOrError('AUTOMATED_EOD_PERFORM_AT_TIME'), 'HH:mm').toDate(),
      parseInt(systemPropertyService.getPropertyOrError('AUTOMATED_EOD_NOTIFICATION_BEFORE_IN_MINUTES')),
      <StartDayAndCloseCounterMode> systemPropertyService.getPropertyOrError('AUTOMATED_EOD_START_DAY'),
      <StartDayAndCloseCounterMode> systemPropertyService.getPropertyOrError('AUTOMATED_EOD_CLOSE_COUNTER')
    ];

    this.model.automatedEodConfig = {
      enabled: enabled,
      performAtTime: performAtTime,
      notificationTime: this.getNextAutomatedEodTime(performAtTime).add(-notificationBeforeInMinutes, 'minutes').toDate(),
      startDayMode: startDayMode,
      closeCounterMode: closeCounterMode
    };

    this.model.officialReceiptConfig = {
      automationEnabled: systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE'
    }

    this.model.collectionsConfig = {
      receiptType: systemPropertyService.getProperty('COLLECTIONS_RECEIPT_TYPE') as CollectionReceiptType
    }

    /*
     * SMS Loan Payment Notification
     */
    this.smsSupportEnabled = systemPropertyService.getPropertyOrError('SMS_SUPPORT') === 'TRUE';
    const smsNotifChannels: SmsNotificationChannel[] = [];
    if (systemPropertyService.getPropertyOrError('SMS_LOAN_PAYMENT_NOTIFICATION_CBS') === 'TRUE') {
      smsNotifChannels.push('CBS');
    }
    if (systemPropertyService.getPropertyOrError('SMS_LOAN_PAYMENT_NOTIFICATION_MOBILE') === 'TRUE') {
      smsNotifChannels.push('MOBILE');
    }
    this.model.smsNotificationConfig = {
      channels: smsNotifChannels,
      template: systemPropertyService.getPropertyOrError('SMS_LOAN_PAYMENT_NOTIFICATION_TEMPLATE')
    }

    this.model.directTransferConfig = {
      tags: await this.fetchExternalTransferTags()
    }
    /*
     * Require AMLA Institution Code for branches
     */
     this.model.amlaInstitutionCodeConfig = {
      required: systemPropertyService.getProperty('REQUIRE_AMLA_INSTITUTION_CODE') === 'TRUE'
    }

    this.model.pchcConfig = {
      directClearingMember: systemPropertyService.getProperty('PCHC_DIRECT_CLEARING_MEMBER') === 'TRUE',
      ledgerAccountCode: this.ledgerTags.find(tag => tag.tagType === 'DIRECT_CHECK_CLEARING_ACCOUNT')?.accountCode
    }

    const assetAccounts = accounts.filter(account => ['ASSET', 'LIABILITY'].includes(account.accountGroup));
    this.ledgerAccounts = addAccountLabels(assetAccounts);

    /*
     * Display actual date time
     */
    this.model.displayRealServerDateTime = systemPropertyService.getProperty('DISPLAY_REAL_SERVER_DATE_TIME') === 'TRUE';
  }

  async fetchExternalTransferTags(): Promise<string> {
    const externalTransferTags = this.ledgerTags.filter(lt => lt.tagType === 'EXTERNAL_TRANSFER').map(lt => lt.code.split('#')[2]).filter(t => t);
    return externalTransferTags.join(',');
  }

  isEodTimeValid(): boolean {
    if (!this.model.automatedEodConfig) {
      return false;
    }

    const performTime = moment(this.model.automatedEodConfig.performAtTime, 'HH:mm');
    const timeFrom = moment('13:00', 'HH:mm');
    const timeTo = moment('23:59', 'HH:mm');

    return (performTime.isSameOrAfter(timeFrom) && performTime.isSameOrBefore(timeTo))
      || performTime.isSame(moment('00:00', 'HH:mm'));
  }

  isNotificationTimeValid(): boolean {
    if (!this.model.automatedEodConfig) {
      return false;
    }

    const notificationBeforeInMinutes = this.notificationBeforeInMinutes();
    return 5 <= notificationBeforeInMinutes && notificationBeforeInMinutes <= 60;
  }

  async save(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const confirmed = await this.confirmation('Do you want to save changes?');
    if (!confirmed) {
      return;
    }

    const request: any = _.cloneDeep(this.model);
    // input sends empty string, change to null if empty
    request.organizationConfig.depEdDeductionCode = request.organizationConfig.depEdDeductionCode || null;
    request.automatedEodConfig.notificationBeforeInMinutes = this.notificationBeforeInMinutes();
    request.automatedEodConfig.performAtTime = moment(this.model.automatedEodConfig?.performAtTime).format('HH:mm');
    request.directTransferConfig.tags = this.getNonBlankAndTrimmedTags();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updated = await this.command.execute('UpdateGlobalSettings', request).toPromise();

    if (!updated?.approvalRequired) {
      this.organizationCache.evict();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.ledgerTagCache.evict();
      window.location.reload();
    }
  }

  private notificationBeforeInMinutes(): number {
    if (!this.model?.automatedEodConfig) {
      throw Error('EOD configuration cannot be undefined');
    }

    const performTime = this.getNextAutomatedEodTime(this.model.automatedEodConfig.performAtTime);
    const notificationTime = moment(this.model.automatedEodConfig.notificationTime);
    return performTime.diff(notificationTime, 'minutes', true);
  }

  private getNextAutomatedEodTime(performAtTime: Date): moment.Moment {
    const performTime = moment(performAtTime, 'HH:mm');

    if (performTime.hour() === 0 && performTime.minute() === 0) {
      // Next performing time is tomorrow (relative to current server time)
      return performTime.add(1, 'days');
    }

    return performTime;
  }

  private getNonBlankAndTrimmedTags(): string[] {
    if(!this.model.directTransferConfig) {
      throw new Error('Missing direct transfer');
    }

    return this.model.directTransferConfig
      .tags
      .split(',')
      .map(t => t.trim())
      .filter(t => t);
  }
}

nxModule.component('globalSettings', {
  templateUrl,
  controller: GlobalSettingsComponent
});
